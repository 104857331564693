import { useCallback, useEffect, useState } from 'react';

const Shuffler = ({ show, done, setDone, fakeAnswers, trueAnswers }) => {
    const [currentAnswer, setCurrentAnswer] = useState();
    const [shufflesLeft, setShufflesLeft] = useState();
    const [possibleAnswers, setPossibleAnswers] = useState([]);
    const [maxWait, setMaxWait] = useState();

    const shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    useEffect(() => {
        if (show && !done) {
            var answers = trueAnswers.concat(fakeAnswers);
            shuffle(answers);

            var index = Math.floor(Math.random() * trueAnswers.length);
            var answer = trueAnswers[index];

            answers = answers.filter(e => e !== answer);
            answers.push(answer);
            setPossibleAnswers(answers);
            setShufflesLeft(answers.length);
            setMaxWait(answers.length + 5)
        }
    }, [show, fakeAnswers, trueAnswers, done]);

    const updateAnswer = useCallback(() => {
        setCurrentAnswer(possibleAnswers[possibleAnswers.length - shufflesLeft]);
        setShufflesLeft(shufflesLeft - 1);
    }, [possibleAnswers, shufflesLeft]);

    useEffect(() => {
        if (shufflesLeft > 0) {
            var timeLeft = maxWait - shufflesLeft;
            setTimeout(updateAnswer, timeLeft * 20);
        } else {
            setDone(true);
        }

    }, [shufflesLeft, maxWait, updateAnswer, setDone]);

    if (!show) {
        return (null);
    }

    return (
        <div className='Shuffler'>
            {currentAnswer}
        </div>
    );
}

export default Shuffler;
