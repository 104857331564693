const Complain = ({ show, link, complain }) => {
    if (!show) {
        return (null);
    }

    return (
        <div className="Complain">
            <a href={link}>{complain}</a>
        </div>
    );
}

export default Complain;
