import './App.css';
import textsJson from './texts.json'
import React, { useEffect, useState } from 'react';
import Splash from './components/Splash';
import Shuffler from './components/Shuffler';
import Complain from './components/Complain';

const App = () => {
  const [started, setStarted] = useState(false);
  const [done, setDone] = useState(false);
  const [theme, setTheme] = useState('');
  const [texts, setTexts] = useState();

  useEffect(() => {
    var path = window.location.pathname.replace('/', '');
    switch (path) {
      case 'zac':
      case 'gnarp':
      case 'bamse':
        setTheme(path);
        break;
      default:
        setTheme('bonk');
    }
    setDone(false);
    setStarted(false);
  }, []);

  useEffect(() => {
    document.getElementById('body').setAttribute('class', theme)
    setTexts(textsJson[theme]);
  }, [theme]);

  const getText = (id) => {
    if (!texts) {
      return '';
    }
    return texts[id];
  }
  const getTexts = (id) => {
    if (!texts) {
      return [];
    }
    return texts[id];
  }

  return (
    <div className="App">
      <Splash started={started} setStarted={setStarted} done={done} setDone={setDone} theme={theme} />
      <Shuffler show={started} done={done} setDone={setDone} fakeAnswers={getTexts('fakeAnswers')} trueAnswers={getTexts('trueAnswers')} />
      <Complain show={done} link={getText('link')} complain={getText('complain')} />
    </div>
  );
}

export default App;
