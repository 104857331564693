import React from 'react';

const Splash = ({ started, setStarted, done, setDone, theme }) => {
    const cssClass = started ? 'Splash Started' : 'Splash';

    const click = () => {
        setStarted(true);
        if (done) {
            setDone(false);
        }
    }

    return (
        <div className={cssClass} onClick={() => click()}>
            <span>Blire bonk?</span>
            <div className='ImageWrapper'>
                <img src={"/" + theme + ".png"} alt="This is supposed to be a face, weird..." />
                {!started && <span>Click me!</span>}
            </div>
        </div>
    );
}

export default Splash;
